
































import { Vue, Component, Ref } from 'vue-property-decorator'
import Modal from '@/components/common/Modal'
import ResidentTable from '../components/ResidentTable.vue'
import RoomDrawer from './RoomDrawer.vue'
import { ElTable } from 'element-ui/types/table'
import _ from 'lodash'
import { is } from '@/utils/helpers'
import mitt from '@/utils/mitt'
import { fetchAll } from '@/utils'

const drawer = Modal(RoomDrawer)

@Component({ components: { ResidentTable, RoomDrawer } })
export default class Rooms extends Vue {
  @Ref('table') table: ElTable
  @Ref('drawer') drawer?: RoomDrawer

  roomList: AnyObj[] = []

  get houseId() {
    return this.$route.params.id
  }

  get roomListWithPersons() {
    return this.roomList.map(room => ({ ...room, persons: [] }))
  }

  mounted() {
    this.fetchRoomList()
  }

  getStatus({ occupy, capacity }: AnyObj) {
    const statusList = [
      { value: 1, label: '空置', name: 'empty' },
      { value: 2, label: '已入住', name: 'normal' },
      { value: 3, label: '超员', name: 'overflow' }
    ]

    if (occupy === 0) return statusList[0]
    if (occupy <= capacity) return statusList[1]
    return statusList[2]
  }

  fetch() {
    this.fetchRoomList()
    mitt.emit('houses/rooms:change')
  }

  async fetchRoomList() {
    const rooms = await fetchAll(offset =>
      this.$api.europa.getRooms({ houseIds: [this.houseId], offset, limit: 100 })
    )

    this.roomList = rooms
  }

  async fetchPersonList(room: AnyObj) {
    const { data } = await this.$api.europa.personByRoom(room.id)

    return data.code === 0 && data.data ? data.data : []
  }

  createRoom() {
    drawer.open({
      mode: 'create',
      data: {},
      handleSubmit: async (formData: AnyObj) => {
        const { data } = await this.$api.europa.createRooms({
          ..._.pickBy(formData, is.ava),
          houseId: this.houseId
        })

        if (data.code === 0) {
          this.$message({ message: '新建成功', type: 'success' })
          this.fetch()
        }
      }
    })
  }

  updateRoom(room: AnyObj) {
    drawer.open({
      mode: 'update',
      data: room,
      handleSubmit: async (formData: AnyObj) => {
        const { data } = await this.$api.europa.updateRoom(
          room.id,
          _.mapValues(formData, v => (is.ava(v) ? v : null))
        )

        if (data.code === 0) {
          this.$message({ message: '编辑成功', type: 'success' })
          this.fetch()
        }
      }
    })
  }

  deleteRoom(room: AnyObj) {
    this.$confirm('房间一经删除无法恢复，是否确定删除?', '提示', {
      type: 'warning',
      callback: async action => {
        if (action === 'confirm') {
          const { data } = await this.$api.europa.deleteRoom(room.id)

          if (!data.error) {
            this.$message({ message: '删除成功', type: 'success' })
            this.fetch()
          }
        }
      }
    })
  }
}
