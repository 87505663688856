










import { Vue, Component } from 'vue-property-decorator'
import ResidentTable from '../components/ResidentTable.vue'

@Component({ components: { ResidentTable } })
export default class Rooms extends Vue {
  residentList: AnyObj[] = []

  get houseId() {
    return this.$route.params.id
  }

  async fetchResidentList() {
    const { data } = await this.$api.europa.getPersonByHouse(this.houseId)

    return data.code === 0 && data.data ? data.data : []
  }
}
