














































import { Vue, Component, Ref, Prop, PropSync } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import { validate } from '@/utils/helpers'
import { ElForm } from 'element-ui/types/form'
import { mitt } from '@/utils/mitt'

@Component({ components: { FormDrawer } })
export default class BindingDrawer extends Vue {
  @PropSync('visible') syncedVisible: boolean
  @Prop() houseId: string

  @Ref() form: ElForm

  options: AnyObj[] = []
  formData: AnyObj = { type: 'name', landlordId: '' }
  rules = {
    landlordId: [{ required: true, message: '选择户主', trigger: 'blur' }]
  }

  visible = false
  loading = false
  selectLoading = false

  get placeholder() {
    return this.formData.type === 'name' ? '请输入姓名进行搜索' : '请输入身份证号进行搜索'
  }

  async remoteMethod(value: string) {
    const { type } = this.formData
    value = value.trim()

    if (!value) return
    if (type === 'identityNumber' && !validate.identityNumber(value)) return

    this.selectLoading = true

    const { data } = await this.$api.europa.getPersons({ type: 'landlord', [type]: value })
    if (data.code === 0) this.options = data.data

    this.selectLoading = false
  }

  async submit() {
    this.form.validate(async valid => {
      if (valid) {
        const { landlordId } = this.formData
        const { data } = await this.$api.europa.updateHouse(this.houseId, { landlordId })

        if (data.code === 0) {
          this.$message({ message: '换绑成功', type: 'success' })
          this.$emit('binded')
          this.syncedVisible = false
          mitt.emit('houses:update', this.houseId)
        }
      }
    })
  }

  close() {
    this.form.resetFields()
    this.formData = { type: 'name', landlordId: '' }
  }
}
