

















































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecurityText from '@/components/common/SecurityText/index.vue'
import mitt from '@/utils/mitt'

@Component({ components: { SecurityText } })
export default class ResidentTable extends Vue {
  @Prop() private readonly fetch: Function
  @Prop({ type: String }) private readonly height: number | string

  private typeList = [
    { value: 'landlord', label: '户主' },
    { value: 'relative', label: '亲属' },
    { value: 'renter', label: '租客' }
  ]
  private loading = false
  private data: AnyObj[] = []

  mounted() {
    this.fetchResidents()
  }

  private async fetchResidents() {
    this.loading = true
    this.data = await this.fetch()
    this.loading = false
  }

  private getType(value: string) {
    return this.typeList.find(type => type.value === value) ?? {}
  }

  private viewResident(resident: AnyObj) {
    this.$router.push({ name: '住户信息查看', params: { id: resident.personId } })
  }

  private deleteResident(resident: AnyObj) {
    const { personId, leaseId } = resident

    this.$confirm('住户一经迁出无法恢复，是否确定迁出?', '提示', {
      type: 'warning',
      callback: async action => {
        if (action === 'confirm') {
          const { data } = await this.$api.europa.deletePerson(personId, leaseId)

          if (!data.error) {
            this.$message({ message: '迁出成功', type: 'success' })
            mitt.emit('houses/residents:change')
            this.fetchResidents()
          }
        }
      }
    })
  }
}
