

























import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator'
import InputSearch from '@/components/common/InputSearch.vue'
import { ElDrawer } from 'element-ui/types/drawer'

/**
 * 在 ElDrawer 尾部添加提交区域，用于在 Drawer 中进行表单提交的场景
 * @displayName Form Drawer
 */
@Component({ components: { InputSearch } })
export default class FormDrawer extends Vue {
  /**
   * 是否显示 Drawer，支持 .sync 修饰符
   */
  @PropSync('visible') syncedVisible: boolean

  /**
   * loading 状态，支持 .sync 修饰符
   */
  @PropSync('loading') syncedLoading: boolean

  @Prop({ default: '85%' }) size: string

  @Prop({ type: Boolean, default: true }) footer: boolean

  @Ref() drawer: ElDrawer

  private close() {
    /**
     * Drawer 关闭的回调
     */
    this.$emit('close')

    this.syncedLoading = false
  }

  private cancel() {
    /**
     * Drawer 取消的回调
     */
    this.$emit('cancel')

    this.syncedLoading = false
    this.syncedVisible = false
  }

  private confirm() {
    /**
     * Drawer 确定的回调
     */
    this.$emit('confirm')
  }
}
