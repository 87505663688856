













































import { Vue, Component } from 'vue-property-decorator'
import LandlordBinder from './components/LandlordBinder.vue'
import Rooms from './Rooms/index.vue'
import Residents from './Residents/index.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import mitt from '@/utils/mitt'

@Component({ components: { LandlordBinder, Rooms, Residents, SecurityText } })
export default class HouseDetail extends Vue {
  house: AnyObj = {}
  address = '-'
  loading = false

  activedTab: 'rooms' | 'residents' | 'records' = 'rooms'
  landlordBinderVisible = false

  get houseId() {
    return this.$route.params.id
  }

  get landlord() {
    return this.house.landlord ?? {}
  }

  get statistic() {
    return this.house.statistic ?? {}
  }

  async mounted() {
    this.fetchHouse()

    mitt.on('houses/rooms:change', this.fetchHouse)
  }

  backToHouses() {
    if (history.length > 1) {
      this.$router.back()
    } else {
      this.$router.push({ name: '房屋管理' })
    }
  }

  async fetchHouse() {
    this.loading = true
    const { data } = await this.$api.europa.getHouse(this.houseId)
    this.loading = false

    if (data.code === 0) {
      this.house = data.data
    }
  }

  handleLandlordCommand(command: 'bind' | 'unbind') {
    switch (command) {
      case 'bind': {
        return this.bindLandlord()
      }

      case 'unbind': {
        return this.unbindLandlord()
      }
    }
  }

  bindLandlord() {
    this.landlordBinderVisible = true
  }

  unbindLandlord() {
    this.$confirm('是否确定解绑当前户主？', '提示', {
      type: 'warning',
      callback: async action => {
        if (action === 'confirm') {
          const { data } = await this.$api.europa.updateHouse(this.houseId, { landlordId: null })

          if (data.code === 0) {
            this.fetchHouse()
            mitt.emit('houses:update', this.houseId)
          }
        }
      }
    })
  }
}
