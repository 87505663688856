











































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import _ from 'lodash'
import { ElForm } from 'element-ui/types/form'

type Options = {
  mode?: 'create' | 'update'
  initialData?: AnyObj
  handleSubmit: (formData: AnyObj) => void
}

@Component({ components: { FormDrawer, InputSearch } })
export default class RoomAdder extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: 'create' }) mode: 'create' | 'update'
  @Prop({ default: () => ({}) }) data: AnyObj
  @Prop({ default: () => () => '' }) handleSubmit: (formData: AnyObj) => string

  @Ref() private readonly form: ElForm

  private formData: AnyObj = this.defaultFormData
  private rules = {
    name: [{ required: true, message: '请输入房间号', trigger: 'blur' }],
    description: [{ min: 0, max: 200, message: '备注应在200字符内，请重新输入', trigger: 'blur' }]
  }
  private loading = false

  private get title() {
    return this.mode === 'create' ? '新建房间' : '编辑房间'
  }

  private get defaultFormData() {
    return { name: '', capacity: 2, description: '' }
  }

  private mounted() {
    this.initialize()
  }

  private initialize() {
    this.formData = { ...this.formData, ..._.pick(this.data, _.keys(this.defaultFormData)) }
  }

  private submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.loading = true

        await this.handleSubmit(this.formData)

        this.loading = false
        this.close()
      }
    })
  }

  private close() {
    this.form.resetFields()
    this.formData = this.defaultFormData
    this.visible = false
  }
}
